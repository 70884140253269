import React from "react";
import Layout from "../components/shared/layout/layout";
import Seo from "../components/seo";
import Intro from "../components/about-us/intro/intro";
import DigitalProduct from "../components/about-us/digital-product/digital-product";
import WhyWeMade from "../components/about-us/why-we-made/why-we-made";
import Leadership from "../components/about-us/leadership/leadership";

const AboutUsPage = () => (
  <Layout>
    <Seo title="About Us" />
    <Intro/>
    <DigitalProduct/>
    <WhyWeMade/>
    <Leadership/>
  </Layout>
)

export default AboutUsPage
