import React from "react";
import "./why-we-made.scss";
import { graphql, useStaticQuery } from "gatsby";

const WhyWeMade = () => {
  const data = useStaticQuery(graphql`
    query {
      whyWeMadeGoldenRatio: file(relativePath: { eq: "why-we-made-golden-ratio.png" }) {
        publicURL
      },
      whyWeMadeYellowBg: file(relativePath: { eq: "why-we-made-yellow-bg.png" }) {
        publicURL
      },
      teamMembers: file(relativePath: { eq: "team-members.svg" }) {
        publicURL
      },
      monthlyInteraction: file(relativePath: { eq: "monthly-interaction.svg" }) {
        publicURL
      },
      countriesServved: file(relativePath: { eq: "countries-servved.svg" }) {
        publicURL
      }
    }
  `)

    return (
      <div id="why-we-made-content">
        <div className="why-we-made-main-img-wrapper">
          <img alt="" className="why-we-made-main-img" src={data.whyWeMadeGoldenRatio.publicURL}/>
        </div>
        <div className="why-we-made-top container">
          <h5 className="why-we-made-pre-title"
              data-sal="slide-left"

              data-sal-easing="ease"
              data-sal-duration="1000"
          >our mission</h5>
          <h2 className="why-we-made-title">
            <p
              data-sal="slide-right"

              data-sal-easing="ease"
              data-sal-duration="1000"
            >Balance time and effort</p>
            <p
              data-sal="slide-left"

              data-sal-easing="ease"
              data-sal-duration="1000"
            >Never compromise on quality</p>
          </h2>
          <p className="why-we-made-text"
             data-sal="slide-right"

             data-sal-easing="ease"
             data-sal-duration="1000"
          > We at Paladin Solutions all share a passion for software. A well built application always stands out.
          The responsiveness, function, efficiency, rhobustness - all bring sheer joy of interaction. It is simply a pleasure to
          use. This is the level that we strive for every day on every project. Our team is excited
          to bring a new level of software craftsmanship to the business world.
          </p>
          <h3 className="why-we-made-quotes"
              data-sal="slide-left"

              data-sal-easing="ease"
              data-sal-duration="1000"
          >There is no such thing as "impossible" in software</h3>
          <p className="why-we-made-text"
             data-sal="slide-right"

             data-sal-easing="ease"
             data-sal-duration="1000"
          >It is only a question of dedication and skill</p>
        </div>
        <img alt="" className="why-we-made-yellow-bg" src={data.whyWeMadeYellowBg.publicURL}/>
        <div className="why-we-made-bottom container">
          <div className="why-we-made-bottom-content"
               data-sal="slide-right"
               data-sal-delay="900"
               data-sal-easing="ease"
               data-sal-duration="1000"
          >
            <img alt="" className="why-we-made-bottom-imgs" src={data.teamMembers.publicURL}/>
            <p className="why-we-made-bottom-bold-text">30+</p>
            <p className="why-we-made-bottom-text">Engineers</p>
          </div>
          <div className="why-we-made-bottom-content"
               data-sal="slide-right"
               data-sal-delay="600"
               data-sal-easing="ease"
               data-sal-duration="1000"
          >
            <img alt="" className="why-we-made-bottom-imgs" src={data.monthlyInteraction.publicURL}/>
            <p className="why-we-made-bottom-bold-text">37M+</p>
            <p className="why-we-made-bottom-text">Lines of Code Written</p>
          </div>
          <div className="why-we-made-bottom-content"
               data-sal="slide-right"
               data-sal-delay="300"
               data-sal-easing="ease"
               data-sal-duration="1000"
          >
            <img alt="" className="why-we-made-bottom-imgs" src={data.countriesServved.publicURL}/>
            <p className="why-we-made-bottom-bold-text">114M+</p>
            <p className="why-we-made-bottom-text">Lines of Code Removed</p>
          </div>
        </div>
      </div>
    )
};

export default WhyWeMade
