import React from "react";
import "./digital-product.scss";
import { graphql, useStaticQuery } from "gatsby";

const DigitalProduct = () => {
  const data = useStaticQuery(graphql`
    query {
      digitalProductDev: file(relativePath: { eq: "digital-product-dev.svg" }) {
        publicURL
      },
      digitalProductMainImg: file(relativePath: { eq: "digital-product-main-img.png" }) {
        publicURL
      },
      digitalProductYellowStars: file(relativePath: { eq: "digital-product-yellow-stars.svg" }) {
        publicURL
      },
      digitalProductGreyGrid: file(relativePath: { eq: "digital-product-grey-grid.svg" }) {
        publicURL
      },
      digitalProductHalfCircle: file(relativePath: { eq: "digital-product-half-circle.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <div className="digital-product-content" id="team">
      <img alt="" className="digital-product-content-img" src={data.digitalProductDev.publicURL}/>
      <div className="digital-product-wrapper container">
        <div className="digital-product-wrapper-left"
             data-sal="slide-right"

             data-sal-easing="ease"
             data-sal-duration="1000"
        >
          <h5 className="digital-product-pre-title">who we are</h5>
          <h3 className="digital-product-title">A team with a passion for excellence</h3>
          <p className="digital-product-text">Paladin Solutions was founded by a veteran Salesforce engineer.
          Development culture runs deep in our roots. Every day we strive to improve and learn. Our engineers are among the very best in the industry and beyond. Our team makes the impossible happen on a daily basis.</p>
        </div>
        <div className="digital-product-wrapper-right">
          <img alt="" className="digital-product-wrapper-img" src={data.digitalProductMainImg.publicURL}
               data-sal="slide-left"
               data-sal-easing="ease"
               data-sal-duration="1000"
          />
          <img alt="" className="digital-product-yellow-stars" src={data.digitalProductYellowStars.publicURL}/>
          <img alt="" className="digital-product-grey-grid" src={data.digitalProductGreyGrid.publicURL}/>
          <div className="digital-product-yellow-circle"></div>
          <img alt="" className="digital-product-half-circle" src={data.digitalProductHalfCircle.publicURL}/>
          {/* <img alt="" className="digital-product-wrapper-img" src={data.digitalProduct.childImageSharp.fixed.src}/> */}
        </div>
      </div>
    </div>
  )
}

export default DigitalProduct
