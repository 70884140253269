import React from "react";
import "./intro.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      goldenRationSystemBackground: file(relativePath: { eq: "golden-ration-system-background.svg" }) {
        publicURL
      },
      goldenRationSystemBackgroundTwo: file(relativePath: { eq: "golden-ration-system-background-two.svg" }) {
        publicURL
      }
    }
  `)

    return (
      <div className="golden-ration-system">
        <div className="container golden-ration-system-inner">
        <img alt="" className="golden-ration-system-inner-goldenBgOne" src={data.goldenRationSystemBackground.publicURL}/>
        <img alt="" className="golden-ration-system-inner-goldenBgTwo" src={data.goldenRationSystemBackgroundTwo.publicURL}/>
          <h2 className="golden-ration-system-inner-title"
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-easing="ease"
              data-sal-duration="1000"
          >
            <p>The Paladin</p>
            <p>Solutions Team</p>
            </h2>
          <p className="golden-ration-system-inner-text"
             data-sal="slide-right"
             data-sal-delay="600"
             data-sal-easing="ease"
             data-sal-duration="1000"
          >
            We are a team with a passionate soul, engineering chops and a mind set on business. Excellence is at the core of everything we do. Our motto is to never stop improving.
            We are thrilled to share our passion and experience with our clients. Every engagement we undertake we give it our all. 
          </p>
          <Link to="#team" className="golden-ration-system-inner-btn"
                  data-sal="slide-up"
                  data-sal-delay="900"
                  data-sal-easing="ease"
                  data-sal-duration="1000"
          >Read more</Link>
        </div>
      </div>
    )
};

export default Intro
