import React from "react";
import "./leadership.scss";
import Slider from "react-slick";
import FirstItem from './first-item/first-item';

class Leadership extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

   render() {
     // const settings = {
     //   focusOnSelect: true,
     //   dots: false,
     //   infinite: true,
     //   slidesToShow: 5,
     //   slidesToScroll: 1,
     //   speed: 1000,
     //   pauseOnHover: true,
     //   autoplaySpeed: 2000,
     //   arrows: true,
     //   responsive: [
     //     {
     //       breakpoint: 1248,
     //       settings: {
     //         slidesToShow: 4,
     //         slidesToScroll: 1,
     //         infinite: true,
     //         dots: false,
     //       },
     //     },
     //     {
     //       breakpoint: 1070,
     //       settings: {
     //         slidesToShow: 3,
     //         slidesToScroll: 1,
     //         infinite: true,
     //         dots: false,
     //       },
     //     },
     //     {
     //       breakpoint: 804,
     //       settings: {
     //         slidesToShow: 2,
     //         slidesToScroll: 1,
     //       },
     //     },
     //     {
     //       breakpoint: 672,
     //       settings: {
     //         slidesToShow: 1,
     //         slidesToScroll: 1,
     //       },
     //     },
     //   ],
     // };

     const settingsTwo = {
       slidesToShow: 1,
       slidesToScroll: 1,
       speed: 1000,
       fade: true,
       cssEase: 'linear',
       arrows: false,
       infinite: true,
       dots: false,
     };

     return (
       <div id="leadership-content">
         <h5 className="leadership-pre-title">the founder</h5>
         <h2 className="leadership-title"
             data-sal="fade"

             data-sal-easing="ease"
             data-sal-duration="1000"
         >Our leadership</h2>
         <Slider {...settingsTwo} asNavFor={this.state.nav1} ref={slider => (this.slider2 = slider)} className="slider-two container">
           <FirstItem/>
         </Slider>
       </div>
     );
   }
}

export default Leadership
