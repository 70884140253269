import React from "react";
import "./first-item.scss";
import LeadershipImage from "../../../../images/leadership-content-bottom-img.png";
import { graphql, useStaticQuery, Link } from "gatsby";

const FirstItem = () => {

  const data = useStaticQuery(graphql`
    query {
      leadershipContentBottomImg: file(relativePath: { eq: "leadership-content-bottom-img.png" }) {
        publicURL
      },
      linkedIn: file(relativePath: { eq: "linked-in.png" }) {
        publicURL
      }
    }
  `);

  return (
    <div className="leadership-content-bottom container">
      <div className="leadership-content-bottom-left">
        <img alt="" className="johndoe-lawrence-big-img" src={LeadershipImage}/>
      </div>
      <div className="leadership-content-bottom-right">
        <h4 className="leadership-bottom-title">Nikita Prokopev</h4>
        <p className="leadership-bottom-text text-top">Nikita is veteran engineer. Started coding at the age of 12. During his undergrad days he was already working with the best brightest at the University of Illinois building applications for NCSA and working with supercomputers. The projects only got bigger from there. Having graduated from UIUC, he joined Salesforce while working on his masters in Computer Science. </p>
        <p className="leadership-bottom-text text-top">He spent almost 9 years at Salesforce R&amp;D, where he worked on a vast number of project being a part of multiple teams. His work at Salesforce started with building the first ever native CPQ application on and for Salesforce. This was when Apex Code and Visualforce hadn't even made their official debut. He worked closely with engineers to help bring to life what would eventually become the metadata API. From there he was instrumental in several efforts including: application security, My Domain, Visualforce. The final project at Salesforce was first ever cloud security scanner.</p>
        <p className="leadership-bottom-text">After leaving Salesforce he launched a services company where he worked as an architect on countless Salesforce implementation projects. His work at Paladin Solutions brings us to today. Many years have passed and his passion for software has only grown along with his skill.</p>
        <Link to="https://linkedin.com/in/nprokopyev" target="_blank"><img alt="" className="linked-in-img" src={data.linkedIn.publicURL}></img></Link>
      </div>
    </div>
  )
};

export default FirstItem
